.reaction {
    background-color: #b9d9ff;
    padding-bottom: 20px;
}

.reaction .title {
    font-size: 25px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.reaction .sub-title {
    font-size: 20px;
    margin-bottom: 30px;
}

.reaction .description {
    background-color: #ebebeb;
    color: #ff8300;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
}


/* step1 */
.reaction .select-feedstock .feedstock-marvin,
.reaction .select-target .target-marvin {
    overflow: hidden;
    min-height: 600px;
    width: 100%;
    border: none;
}

.reaction .select-feedstock .select-btn,
.reaction .select-target .select-btn {
    margin-top: 30px;
    text-align: center;
}

.reaction .select-feedstock .select-btn button ,
.reaction .select-target .select-btn button {
    background-color: transparent;
    color: #0d6efd;
    font-size: 50px;
    border: none;
}

.reaction .select-feedstock .title,
.reaction .select-target .title,
.reaction .rxn-step-title,
.reaction .advanced .title,
.reaction .search-result .title  {
    font-size: 20px;
    margin-bottom: 20px;
    text-decoration: none;
}

.reaction .select-feedstock .reference-structure img,
.reaction .select-feedstock .changed-structure img,
.reaction .select-feedstock .unchanged-structure img,
.reaction .select-target .reference-structure img,
.reaction .select-target .changed-structure img,
.reaction .select-target .unchanged-structure img  {
    max-height: 160px;
    padding-bottom: 10px;
}

.reaction .see-results {
    margin: 40px 0;
}

.reaction .reference-structure,
.reaction .unchanged-structure,
.reaction .changed-structure {
    min-height: 200px;
}

.reaction .hydrogen .dropdown,
.reaction .atom .dropdown  {
    display: inline;
    margin: 0 5px;
}

.reaction .advanced .sim-thresh {
    margin-bottom: 10px;
}

.reaction .advanced .sim-thresh-value {
    display: inline-block;
    width: 80px;
}

.reaction .search-result .show-mole .mole-type {
    display: inline-block;
    margin: 0 5px;
}

.reaction .search-result .show-mole .top-n {
    display: inline-block;
    width: 80px;
    margin: 0 5px;
}

.reaction .search-result .show-mole .show-mole-item {
    margin-bottom: 10px;
    text-align: center;
}