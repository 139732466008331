.synthetic {
    background-color: #b9d9ff;
    padding-bottom: 20px;
}

.synthetic .title {
    font-size: 25px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.synthetic .sub-title {
    font-size: 20px;
    margin-bottom: 30px;
}

.synthetic .description {
    background-color: #ebebeb;
    color: #ff8300;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
}

.synthetic .tooltip-button {
    padding: 0;
    background: transparent;
    border: none;
}

.synthetic .tooltip-button .question {
    margin-bottom: 5px;
    color: #ff8300;
}


/* step1 */
.synthetic .select-target .target-marvin,
.synthetic .select-feedstock .feedstock-marvin,
.synthetic .fragment-marvin,
.synthetic .solvents-marvin,
.synthetic .reagents-marvin,
.synthetic .reactants-marvin,
.synthetic .products-marvin {
    overflow: hidden;
    min-height: 600px;
    width: 100%;
    border: none;
}

.synthetic .select-target .select-btn,
.synthetic .select-feedstock .select-btn {
    margin-top: 200px;
    text-align: center;
}

.synthetic .select-target .select-btn button,
.synthetic .select-feedstock .select-btn button {
    background-color: transparent;
    color: #0d6efd;
    font-size: 50px;
    border: none;
}

.synthetic .select-target .title,
.synthetic .select-feedstock .title,
.synthetic .fragment .title,
.synthetic .params .title,
.synthetic .long-params .title,
.synthetic .advanced .title,
.synthetic .search-result .title {
    font-size: 20px;
    margin-bottom: 20px;
    text-decoration: none;
}

.synthetic .select-target .target-type,
.synthetic .select-feedstock .feedstock-type {
    margin-bottom: 20px;
}

.synthetic .selected-targets,
.synthetic .selected-feedstocks {
    background-color: #ffffff;
    color: #ff8300;
    height: 600px;
    margin: auto;
    padding: 15px;
    border: 1px solid;
    text-align: center;
    width: 100%;
    overflow-y: scroll;
}

.synthetic .selected-targets .selected-target-item,
.synthetic .selected-feedstocks .selected-feedstock-item {
    text-align: center;
    margin-bottom: 10px;
}

.synthetic .frag-modal .get-labled-mol {
    margin-top: 20px;
    text-align: center;
}

.synthetic .frag-modal .get-labled-mol button {
    background-color: transparent;
    color: #0d6efd;
    font-size: 50px;
    border: none;
}

.synthetic .frag-modal .frag-params {
    text-align: center;
}

.synthetic .frag-modal .frag-pic {
    text-align: center;
}

.synthetic .frag-modal .frag-pic img {
    height: 300px;
}

.synthetic .frag-modal .dropdown {
    display: inline;
    margin: 0 5px;
}

.synthetic .fragment .keep-frags {
    background-color: #ffffff;
    color: #ff8300;
    width: 100%;
    margin: auto;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.synthetic .fragment .keep-frags .keep-frags-item {
    text-align: center;
    margin-bottom: 10px;
}

.synthetic .fragment .keep-frags .keep-frags-item img {
    height: 150px;
}

.synthetic .fragment .drop-frags {
    background-color: #ffffff;
    color: #ff8300;
    width: 100%;
    margin: auto;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.synthetic .fragment .drop-frags .drop-frags-item {
    text-align: center;
    margin-bottom: 10px;
}

.synthetic .fragment .drop-frags .drop-frags-item img {
    height: 150px;
}

.synthetic .params .yield-thres {
    margin-top: 20px;
}

.synthetic .params .yield-thres-value {
    display: inline-block;
    width: 80px;
}

.synthetic .select-target .search-direction {
    margin-top: 20px;
}

.synthetic .select-target .search-direction-dropdown {
    display: inline-block;
    width: 80px;
}

.synthetic .long-params .search-method {
    margin-bottom: 20px;
}

.synthetic .long-params .dropdown {
    display: inline-block;
    margin: 0 5px;
}

.synthetic .see-results {
    margin: 40px 0;
}

.synthetic .advanced .dropdown {
    display: inline-block;
    margin: 0 5px;
}

.synthetic .advanced .others {
    margin-top: 10px;
}

.synthetic .advanced .set-products {
    margin-top: 10px;
}

.synthetic .advanced .solvents-string,
.synthetic .advanced .reagents-string {
    display: inline-block;
    width: 200px;
    margin-left: 5px;
}

.synthetic .advanced .reactants-string,
.synthetic .advanced .products-string {
    display: inline-block;
    width: 200px;
    margin-left: 5px;
}

.synthetic .advanced .similarity-thres-value {
    display: inline-block;
    width: 80px;
}

.synthetic .advanced .reactions {
    margin-top: 10px;
}

.synthetic .advanced .molecules-string,
.synthetic .advanced .reactions-string {
    display: inline-block;
    width: 200px;
    margin-left: 5px;
}

.synthetic .advanced .rank-routes,
.synthetic .advanced .yield-thresh,
.synthetic .advanced .yield-steps,
.synthetic .advanced .sri-thresh,
.synthetic .advanced .sri-steps,
.synthetic .advanced .top-n,
.synthetic .advanced .simi-thresh {
    margin-bottom: 10px;
}

.synthetic .advanced .yield-thresh-value,
.synthetic .advanced .yield-steps-value,
.synthetic .advanced .sri-thresh-value,
.synthetic .advanced .sri-steps-value,
.synthetic .advanced .top-n-value,
.synthetic .advanced .simi-thresh-value {
    display: inline-block;
    width: 80px;
    margin: 0 5px;
}

.synthetic .advanced .long-mode {
    margin-bottom: 10px;
}


/* step2 */
.synthetic .search-result .rank-on,
.synthetic .search-result .top-routes,
.synthetic .search-result .top-reactions {
    margin-top: 15px;
}

.synthetic .search-result .top-routes-value {
    width: 80px;
}

.synthetic .search-result .synth-routes {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ff8300;
}

.synthetic .search-result .synth-routes img {
    max-width: 200px;
}

.synthetic .search-result .synth-routes .route-metrics {
    margin: 10px;
}

.synthetic .search-result .synth-routes .reactions-per-page {
    text-align: center;
    margin-top: 10px;
}

.synthetic .search-result .synth-routes .reactions-pagination {
    justify-content: center;
    display: flex;
    margin-top: 8px;
}

.synthetic .search-result .synth-routes .reactions-per-page .dropdown {
    display: inline;
    margin-left: 5px;
}

.synthetic .search-result .refresh-routes {
    margin-top: 20px;
}

.synthetic .search-result .mole-id {
    margin-left: 50px;
    margin-bottom: 10px;
}

.synthetic .search-result .route-reaction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.synthetic .search-result .route-reaction .reaction-arrow {
    height: 20px;
    margin: 5px 0;
}

.synthetic .search-result .route-reaction .reaction-id {
    text-align: center;
    width: 100%;
    line-break: anywhere;
}

.synthetic .search-result .show-mole .mole-type {
    display: inline-block;
    margin: 0 5px;
}

.synthetic .search-result .show-mole .top-n {
    display: inline-block;
    width: 80px;
    margin: 0 5px;
}

.synthetic .search-result .show-mole .show-mole-item {
    margin-bottom: 10px;
    text-align: center;
}

.synthetic .search-result .show-mole .molecules-string {
    display: inline-block;
    width: 500px;
    margin-left: 5px;
}

.synthetic .search-result .feedstocks-string {
    display: inline-block;
    margin-bottom: 10px;
}

.synthetic .search-result .dropdown-item {
    min-height: 32px;
}

.synthetic .search-result .reaction-details {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ff8300;
    padding: 10px;
    max-height: 400px;
    overflow-y: scroll;
}

.synthetic .search-target .similar-mol-box,
.synthetic .search-feedstock .similar-mol-box {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.synthetic .search-target .similar-mol,
.synthetic .search-feedstock .similar-mol{
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}