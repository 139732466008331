.header .brand{
    font-size: 25px;
    font-weight: bold;
    color: #ff8300 !important;
    flex-grow: 1;
}

.header .navbar-collapse.collapse {
    flex-grow: 0;
}

.navbar-light .navbar-nav .nav-link.active{
    color: #ff8300;
    text-decoration: underline;
}