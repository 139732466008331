.analogue {
    background-color: #b9d9ff;
    padding-bottom: 20px;
}

.analogue .title {
    font-size: 25px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.analogue .sub-title {
    font-size: 20px;
    margin-bottom: 30px;
}

.analogue .tooltip-button {
    padding: 0;
    background: transparent;
    border: none;
}

.analogue .question {
    margin-bottom: 2px;
    color: #ff8300;
}

.popover {
    max-width: 600px;
}

.popover-body {
    max-height: 700px;
    overflow: auto;
}

.popover img {
    max-width: 550px;
}

.analogue .description {
    background-color: #ebebeb;
    color: #ff8300;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
}


/* step1 */
.analogue .select-feedstock .feedstock-marvin,
.analogue .select-target .target-marvin {
    overflow: hidden;
    min-height: 600px;
    width: 100%;
    border: none;
}

.analogue .select-feedstock .select-btn,
.analogue .select-target .select-btn {
    margin-top: 30px;
    text-align: center;
}

.analogue .select-feedstock .select-btn button ,
.analogue .select-target .select-btn button {
    background-color: transparent;
    color: #0d6efd;
    font-size: 50px;
    border: none;
}

.analogue .select-feedstock .title,
.analogue .select-target .title,
.analogue .rxn-step-title,
.analogue .advanced .title,
.analogue .search-result .title  {
    font-size: 20px;
    margin-bottom: 20px;
    text-decoration: none;
}

.analogue .select-feedstock .reference-structure img,
.analogue .select-feedstock .changed-structure img,
.analogue .select-feedstock .unchanged-structure img,
.analogue .select-target .reference-structure img,
.analogue .select-target .changed-structure img,
.analogue .select-target .unchanged-structure img  {
    max-height: 160px;
    padding-bottom: 10px;
}

.analogue .see-results {
    margin: 40px 0;
}

.analogue .hydrogen .dropdown,
.analogue .chirality .dropdown,
.analogue .atom .dropdown  {
    display: inline;
    margin: 0 5px;
}

.analogue .advanced .sim-thresh {
    margin-bottom: 10px;
}

.analogue .advanced .sim-thresh-value {
    display: inline-block;
    width: 80px;
}

.analogue .reference-structure,
.analogue .unchanged-structure,
.analogue .changed-structure {
    min-height: 200px;
}


/* step2 */
.analogue .search-result .top-routes,
.analogue .search-result .top-reactions {
    margin-top: 15px;
}

.analogue .search-result .top-routes-value {
    width: 80px;
}

.analogue .search-result .synth-routes {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ff8300;
}

.analogue .search-result .synth-routes img {
    max-width: 200px;
}

.analogue .search-result .synth-routes .route-metrics {
    margin: 10px;
}

.analogue .search-result .synth-routes .reactions-per-page {
    text-align: center;
    margin-top: 10px;
}

.analogue .search-result .synth-routes .reactions-pagination {
    justify-content: center;
    display: flex;
    margin-top: 8px;
}

.analogue .search-result .synth-routes .reactions-per-page .dropdown {
    display: inline;
    margin-left: 5px;
}

.analogue .search-result .refresh-routes {
    margin-top: 20px;
}

.analogue .search-result .mole-id {
    margin-left: 50px;
    margin-bottom: 10px;
}

.analogue .search-result .route-reaction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.analogue .search-result .route-reaction .reaction-arrow {
    height: 20px;
    margin: 5px 0;
}

.analogue .search-result .route-reaction .reaction-id {
    text-align: center;
    width: 100%;
    line-break: anywhere;
}

.analogue .search-result .show-mole .show-mole-item {
    margin-bottom: 10px;
    text-align: center;
}

.analogue .search-result .show-mole .molecules-string {
    display: inline-block;
    width: 500px;
    margin-left: 5px;
}

.analogue .search-result .feedstocks-string {
    display: inline-block;
    margin-bottom: 10px;
}

.analogue .search-result .reaction-details {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ff8300;
    padding: 10px;
    max-height: 400px;
    overflow-y: scroll;
}