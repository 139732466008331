.chiral {
    background-color: #b9d9ff;
    padding-bottom: 20px;
}

.chiral .title {
    font-size: 25px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.chiral .sub-title {
    font-size: 20px;
    margin-bottom: 30px;
}

.chiral .description {
    background-color: #ebebeb;
    color: #ff8300;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
}


/* step1 */
.chiral .see-results {
    margin: 40px 0;
}

.chiral .select-target .title,
.chiral .advanced .title,
.chiral .search-result .title  {
    font-size: 20px;
    margin-bottom: 20px;
    text-decoration: none;
}

.chiral .select-target .target-marvin {
    overflow: hidden;
    min-height: 600px;
    width: 100%;
    border: none;
}

.chiral .select-target .select-btn {
    margin-top: 200px;
    text-align: center;
}

.chiral .select-target .select-btn button {
    background-color: transparent;
    color: #0d6efd;
    font-size: 50px;
    border: none;
}

.chiral .select-target .chiral-structure {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chiral .hydrogen .dropdown,
.chiral .aromaticity .dropdown,
.chiral .atom .dropdown  {
    display: inline;
    margin: 0 5px;
}

.chiral .advanced .sim-thresh {
    margin-bottom: 10px;
}

.chiral .advanced .sim-thresh-value {
    display: inline-block;
    width: 80px;
}


/* step2 */
.chiral .search-result .top-routes {
    margin-top: 15px;
}

.chiral .search-result .top-routes-value {
    width: 80px;
}

.chiral .search-result .refresh-routes {
    margin-top: 20px;
}

.chiral .search-result .synth-routes {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ff8300;
}

.chiral .search-result .synth-routes .reactions-per-page {
    text-align: center;
    margin-top: 10px;
}

.chiral .search-result .synth-routes .reactions-pagination {
    justify-content: center;
    display: flex;
    margin-top: 8px;
}

.chiral .search-result .synth-routes .reactions-per-page .dropdown {
    display: inline;
    margin-left: 5px;
}

.chiral .tooltip-button {
    padding: 0;
    background: transparent;
    border: none;
    margin: 0 5px 3px 5px;
}

.chiral .question {
    margin-bottom: 2px;
    color: #ff8300;
}

.chiral .chiral-reaction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chiral .chiral-reaction .reaction-arrow {
    height: 20px;
    margin: 5px 0;
}

.chiral .chiral-reaction .reaction-id {
    text-align: center;
    width: 100%;
    line-break: anywhere;
}

.chiral .rxt-image {
    max-width: 200px;
}

.chiral .search-result .show-mole .show-mole-item {
    margin-bottom: 10px;
    text-align: center;
}

.chiral .search-result .show-mole .molecules-string {
    display: inline-block;
    width: 500px;
    margin-left: 5px;
}

.chiral .search-result .feedstocks-string {
    display: inline-block;
    margin-bottom: 10px;
}

.chiral .search-result .reaction-details {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ff8300;
    padding: 10px;
    max-height: 400px;
    overflow-y: scroll;
}