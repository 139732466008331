.user {
    width: 100%;
    padding: 15px 0;
    background-color: #b9d9ff;
}

.user .user-name {
    text-align: right;
}

.user .sign-in {
    height: 100vh;
    text-align: center;
}

.user .sign-in .title {
    margin: 50px 0;
}