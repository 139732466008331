.network {
    background-color: #b9d9ff;
    padding: 20px;
}

.network .title {
    font-size: 25px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.network .reaction-network .card-body {
    height: 600px;
}

.network .description {
    background-color: #ebebeb;
    color: #ff8300;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 20px;
}